import { useFirestore } from '../../hooks/useFirestore'
import { useFirestoreButton } from "../../hooks/useFirestoreButton";
import { useFirestoreButtonTwo } from "../../hooks/useFirestoreButtonTwo";
import { useFirestoreSetMap } from "../../hooks/useFirestoreSetMap";
import { useFirestoreSetDeviceClick } from "../../hooks/useFirestoreSetDeviceClick";
import { useFirestoreSetDeviceFirstClick } from "../../hooks/useFirestoreSetDeviceFirstClick";
import trash_logo from '../../images/trash_icon.png';
import check_logo from '../../images/check_logo.png';
import refresh_logo from '../../images/refresh_icon.png';
import { useAuthContext } from "../../hooks/useAuthContext";

// styles
import styles from './Home.module.scss'
import {useFirestoreDrop} from "../../hooks/useFirestoreDrop";
import {useState} from "react";


export default function TrackerList({ trackers }) {
    const { user } = useAuthContext()
    const { updateDocumentTrack, response2 } = useFirestoreButton("track_info")
    const { updateDocumentSensor, response3 } = useFirestoreButtonTwo("customer_state")
    const { updateDocumentSetMap, response4 } = useFirestoreSetMap('map_configs_test')
    const { updateDocumentSetClick, response5 } = useFirestoreSetDeviceClick('tracker_click_two')
    const { updateDocumentFirstClick, response6 } = useFirestoreSetDeviceFirstClick('map_configs_test')
    const { addDocument, response } = useFirestore('customer_login')
    const [ mapState, setMapState ] = useState(true)
    const eta_label = "ETA: "
    const arrival_label = "Arrived: "

    const handleRefresh = (device_lat, device_long, device_display, device_uid) => {
        addDocument({
            cust_uid: user.uid
        })
        if (mapState===true){
            setMapState(false)
        }
        else {
            setMapState(true)
        }
        updateDocumentSetMap(user.uid, mapState, device_lat, device_long)
        updateDocumentSetClick(user.uid, mapState, device_lat, device_long, device_display, device_uid)
    }

    const handleClick = (track_uid, track_state, senseid, device_lat, device_long, device_display, device_uid) => {
        let trackStateString = String(track_state)
        updateDocumentTrack(track_uid, trackStateString)
        let senseidString = String(senseid)
        updateDocumentSensor(senseid)
        if (mapState===true){
            setMapState(false)
        }
        else {
            setMapState(true)
        }
        updateDocumentSetMap(user.uid, mapState, device_lat, device_long)
        updateDocumentSetClick(user.uid, mapState, device_lat, device_long, device_display, device_uid)
        updateDocumentFirstClick(user.uid)
    };

    const handleDeviceClick = (device_lat, device_long, device_display, device_uid) => {
        if (mapState===true){
            setMapState(false)
        }
        else {
            setMapState(true)
        }
        updateDocumentSetMap(user.uid, mapState, device_lat, device_long)
        updateDocumentSetClick(user.uid, mapState, device_lat, device_long, device_display, device_uid)
    }

    return (
        <div className={styles.deviceInfoFlexColumn}>
            {/* Flex column for detailed device information. */}
            {trackers.map((tracker) => (
                <div className={styles.deviceInfoContentBox}>
                    {/* Box for device information details. */}

                    <div className={styles.deviceInfoFlexRow}>
                        {/* Flex row to organize device info items. */}
                        <div
                            className={tracker.light_color === 0 ? styles.deviceRectangle_good : tracker.light_color === 1 ? styles.deviceRectangle_amber : tracker.light_color === 2 ? styles.deviceRectangle_bad : styles.deviceRectangle_blank}/>
                        <h2 className={styles.deviceNameTitle}>
                            {/* Title indicating device name. */}
                            Device:
                        </h2>
                        <h2 className={styles.trackerIdText}>
                            {/* Text showing the unique tracker ID. */}
                            <span className={styles.trackerIdText} onClick={() => handleDeviceClick(tracker.device_lat_long[0], tracker.device_lat_long[1], tracker.tracker_display, tracker.tracker_uid)}>{tracker.tracker_display}</span>
                        </h2>
                        <h2 className={styles.statusTitle}>
                            {/* Title indicating device status. */}
                            Status:
                        </h2>
                        <h2 className={tracker.status === "On Time" || tracker.status === "Complete" ? styles.statusText_good : tracker.status === "Late Risk" ? styles.statusText_amber : styles.statusText_bad}>
                            {/* Text indicating the current device status. */}
                            {tracker.status}
                        </h2>
                        <h2 className={styles.etaTitle}>
                            {/* Title for the estimated time of arrival. */}
                            {tracker.has_arrived ? arrival_label : eta_label}
                        </h2>
                        <h2 className={tracker.status === "On Time" || tracker.status === "Complete" ? styles.etaText_good : tracker.status === "Late Risk" ? styles.etaText_amber : styles.etaText_bad}>
                            {/* Text showing the ETA. */}
                            {tracker.has_arrived ? tracker.arrival_time : tracker.eta_text}
                        </h2>
                        <h2 className={styles.temperatureTitle}>
                            {/* Title for displaying temperature. */}
                            Temp:
                        </h2>
                        <h2 className={tracker.temp_status ? styles.temperatureText_good : styles.temperatureText_bad}>
                            {/* Text showing the current temperature. */}
                            {tracker.temp} F
                        </h2>
                        <h2 className={styles.humidityTitle}>
                            {/* Title for displaying humidity level. */}
                            Humidity:
                        </h2>
                        <h2 className={tracker.humid_status ? styles.humidityText_good : styles.humidityText_bad}>
                            {/* Text showing the current humidity level. */}
                            {tracker.humidity}%
                        </h2>
                        <h2 className={styles.batteryTitle}>
                            {/* Title for displaying humidity level. */}
                            Battery:
                        </h2>
                        <h2 className={tracker.battery_status === "Good" ? styles.batteryText_good : tracker.battery_status === "Low" ? styles.batteryText_amber : styles.batteryText_bad}>
                            {/* Text showing the current humidity level. */}
                            {tracker.battery_status}
                        </h2>
                    </div>

                    {tracker.track_info.track_state && (<img className={styles.lastFlexWrapperImage} src={tracker.status === "Complete" ? check_logo: trash_logo} alt="alt text" onClick={() => {tracker.track_info.track_state ? handleClick(tracker.track_info.track_uid, tracker.status, tracker.tracker_display, tracker.device_lat_long[0], tracker.device_lat_long[1], tracker.tracker_display, tracker.tracker_uid): console.log('Not Active')}}/>)}
                    <div>
                        <img className={styles.lastFlexWrapperImageRefresh} src={refresh_logo} alt="alt text" onClick={() => {handleRefresh(tracker.device_lat_long[0], tracker.device_lat_long[1], tracker.tracker_display, tracker.tracker_uid)}}/>
                    </div>

                </div>
            ))}
        </div>
    )
}