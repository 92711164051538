import { Link } from "react-router-dom"
import {useEffect, useState} from "react";
import { useAuthContext } from "../hooks/useAuthContext"
import { useLogout } from '../hooks/useLogout'
import { useFirestore } from "../hooks/useFirestore";
import { useFirestoreClearMap } from "../hooks/useFirestoreClearMap";
import logo from '../images/login_logo.png';
import bell from '../images/bell_icon.png';
import tdata from '../images/data_logo.png'
import maptrack from '../images/map_icon.png'
import dashlogo from '../images/chart_icon.png'
import userlogo from '../images/user_logo.png'

// styles
import styles from './Navbar.module.css'


export default function Navbar() {
    const { logout } = useLogout()
    const { user } = useAuthContext()
    const { updateDocumentClicked, response2 } = useFirestoreClearMap('map_configs')

    const handleClick = () => {
        updateDocumentClicked(user.uid, false)
        logout()
    };

    return (
        <nav className={styles.navbar}>
            <ul>
                <img src={logo} className="App-logo" alt="BridgTRk"/>

                {user && (
                    <>
                        <li className="tooltip">
                            <li className="tooltiptextfront">Dashboard</li>
                            <Link to={"/"}><img src={dashlogo} className="nav_icon" alt="Dashboard"/></Link>
                        </li>
                        <li className="tooltip">
                            <li className="tooltiptext">Create TRK</li>
                            <Link to={"/create-track"}><img src={maptrack} className="nav_icon_back"
                                                            alt="Create Track"/></Link>
                        </li>
                        <li className="tooltip">
                            <li className="tooltiptext">Tracker Data</li>
                            <Link to={"/tracker-data"}><img src={tdata} className="nav_icon_back"
                                                            alt="Tracker Data"/></Link>
                        </li>
                        <li className="tooltip">
                            <li className="tooltiptext">Notifications</li>
                            <Link to={"/"}><img src={bell} className="nav_icon_back" alt="Notifications"/></Link>
                        </li>
                        <li className="tooltip">
                            <li className="tooltiptext">User Settings</li>
                            <Link to={"/user-settings"}><img src={userlogo} className="nav_icon_back" alt="User"/></Link>
                        </li>
                        <li>{user.email}</li>
                        <button className="btn" onClick={handleClick}>Logout</button>
                    </>
                )}
            </ul>
        </nav>
    )
}