import { useEffect, useState } from "react"
import { projectFirestore } from "../firebase/config"
import {collection, doc, getDoc, onSnapshot, orderBy, query, where} from "firebase/firestore"


export const useCollectionMap = (collection_name, doc_id) => {
    const [mapCenter, setMapCenter] = useState(null)
    const [error, setError] = useState(null)

    // if we don't use a ref --> infinite loop in useEffect
    // _query is an array and is "different" on every function call

    useEffect(() => {

        let refDoc = doc(projectFirestore, collection_name, doc_id)

        const unsubscribe = onSnapshot(refDoc,snapshot => {
            if (snapshot.data()){
                const data = snapshot.data();
                // let final_results = []
                // final_results.push(data.center[0], data.center[1], data.click);

                // .filter(sensorId => data.sensor_track_state[sensorId] === false) // Filter sensors with value "false"
                // .map(sensorId => ({
                //     id: sensorId,
                //     name: sensorId // You might want to fetch the name from another source if available
                // }));
                setMapCenter(data)
                setError(null)
            }
        }, error => {
            console.log(error)
            setError('could not fetch the data')
        })

        // unsubscribe on unmount
        return () => unsubscribe()

    }, [collection_name, doc_id])
    return { mapCenter, error }
}